/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentHistoryTable from "./paymentHistoryTable";
import {
  getPaymentHistorytableList, setInitialPaymentHistoryPage, setPaymentHistoryRecordShow, setPaymentHistoryItems, setIsHistoryVisible,
  setRecordsShow,
  setSnackbar,
  getPaymentHistorytable,
} from "./paymentHistoryTableSlice";
import {
  formatMoney,
  getBrowserDate,
  Currency,
  cleanObject
} from "../../sharedfiles/helper";
import { getFinancials } from "../primaryplayer/PrimaryPlayerSlice";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Skelton from "../Common/skelton/Skelton";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import ManualDialog from "./ManualDialog";
import { setIntervalStopper } from "../AppSlice";
import { manualDropdown, paymentHeaders } from "../Common/data/mapData";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PYMENTHISTORY_CONSTANTS } from "../../sharedfiles/Constants";
import { getPaymentSearchtableList } from "../PaymentSearch/paymentSearchSlice"
class PaymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentFilterData: [],
      payment: "",
      paymentType: "ALL",
      adjustmentType: "",
      amount: 0,
      comments: "",
      description: "",
      data: [],
      activePage: 1,
      itemsPerPage: 25,
      recordsFrom: "1",
      recordsTo: "25",
      totalPages: "",
      isValid: true,
      document: "",
      modelMoneyError: false,
      condition: false,
      payloadData: {
        paymentType: "",
        userId: localStorage.getItem("userId"),
        status: "",
      },
      status: "ALL",
      statusError: false,
      netWithdrawal: this.props.financialData.playerSummaryVw?.netWithdrawal
        ? this.props.financialData.playerSummaryVw?.netWithdrawal
        : 0,
      netDeposit: this.props.balanceData.playerSummaryVw?.deposit
        ? this.props.balanceData?.playerSummaryVw.deposit
        : 0,
      balanceAmount: 0,
      customerID: "",
      csvData: [],
      pdfBody: [],
      option: "ONLINE",
      headers: paymentHeaders,
    };
    this.onChangePaymentHistory = this.onChangePaymentHistory.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.onSearchRecord = this.onSearchRecord.bind(this);
    this.resetButton = this.resetButton.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    this.callApi = this.callApi.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
  }

  callApi(pageNumber, itemsPerPage, data) {
    this.props.dispatch(setIsHistoryVisible(false));
    this.props.dispatch(
      getFinancials({ user_id: localStorage.getItem("userId") })
    );
    this.props.dispatch(
      getPaymentHistorytable(
        pageNumber - 1,
        itemsPerPage,
        data
        // { id: localStorage.getItem("userId") },
      )
    );
  }

  headers = [
    [
      "Transaction Id",
      "Created On",
      "Updated On",
      "Created By",
      `Amount ${Currency()}`,
      "State",
      `TDS Amount ${Currency()}`,
      "TDS Percentage",
      "Method",
      "Payment Type",
      "Payment Method",
      "Payment Description",
      "Bank Name",
      "Reference ID",
    ],
  ];

  myDocument(e) {
    this.setState({ document: e.target.value });
  }

  updateTableToDocs() {
    setTimeout(() => {
      const permission = this.props.tdsPermission
      const data =
        this.props.paymentHistoryUserData.data &&
        this.props.paymentHistoryUserData.data &&
        Array.isArray(this.props.paymentHistoryUserData.data) &&
        this.props.paymentHistoryUserData.data.map((elt) => [
          elt.id,
          getBrowserDate(elt.createdDate),
          getBrowserDate(elt.updatedDate),
          elt.addedBy,
          elt.updatedByUserName || '-',
          formatMoney(elt.amount),
          elt.status,
          elt.tdsAmount ? formatMoney(elt.tdsAmount) : "0",
          elt.tdsPercentage,
          elt.paymentType,
          elt.paymentMethodType,
          elt.modePaymentClient,
          elt.paymentDesc,
          elt.bankName,
          elt.transactionRef,
        ]);
      const filterData = !permission && data?.map((record) => record?.filter((v, ind) => ind !== 6 && ind !== 7))
      const opData = permission ? data : filterData;
      const csvData =
        data?.length > 0
          ? [this.state.headers, ...opData]
          : [this.state.headers, [["No data found"]]];
      this.setState({ pdfBody: opData });
      this.setState({ csvData: csvData });
    }, 500);
  }

  exportPDF() {
    const title = "Payment History Report";

    const styles = {
      2: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, [this.state.headers], this.state.pdfBody, styles);
  }
  resetState() {
    this.setState({
      paymentType: "ALL",
      status: "ALL",
      statusError: false,
      option: "ONLINE",
      payloadData: {
        paymentType: "ALL",
        userId: localStorage.getItem("userId"),
        status: "ALL",
      },
    });
  }

  resetCall(data) {
    this.props.dispatch(setIsHistoryVisible(false));
    this.props.dispatch(setInitialPaymentHistoryPage(1));
    this.props.dispatch(setPaymentHistoryItems(25));
    this.props.dispatch(setPaymentHistoryRecordShow([1, 25]));
    this.props.dispatch(getPaymentHistorytable(0, 25, data));
  }

  resetButton() {
    const id = window.location.pathname.split("/")[2];
    const data = {
      paymentType: this.state.paymentType,
      paymentApprovalType: this.state.option,
      userId: id,
      status: this.state.status,
    };
    this.resetState();
    this.resetCall(data);
  }

  onChangePaymentHistory(e) {
    this.setState({ paymentType: e.target.value });
    //  this.setState({ condition: false });
    if (e.target.value === "ALL") {
      this.setState({ adjustmentType: "" });
    }
  }

  onChangeOption(e) {
    this.setState({ option: e.target.value });
    if (e.target.value === "ONLINE") {
      this.setState({ adjustmentType: "" });

    }
  }
  onChangeStatus(e) {
    if (e.target.value.length > 0) {
      this.setState({ statusError: false });
    }

    this.setState({ status: e.target.value });
  }
  onChangeAdjustment(e) {
    this.setState({ adjustmentType: e.target.value });
  }
  // searchRecords = () => {
  //   this.setState({
  //     paymentFilterData: { paymentType: this.state.paymentType },
  //   });
  // };

  searchData() {
    return {
      paymentType: this.state.paymentType,
      userId: localStorage.getItem("userId"),
      status: this.state.status,
      paymentApprovalType: this.state.option,
      manualAdjustment: this.state.adjustmentType,
    }
  }

  onSearchRecord(e) {
    e.preventDefault();
    const data = cleanObject(this.searchData());
    this.setState({ payloadData: data });
    if (this.state.paymentType !== "" && this.state.status !== "") {
      this.props.dispatch(setIsHistoryVisible(false));
      if (this.props.initialPaymentHistoryPage == 1) {
        this.callApi(
          this.props.initialPaymentHistoryPage,
          this.props.paymentHistoryItems,
          data
        );
        this.props.dispatch(setInitialPaymentHistoryPage(1))
        this.props.dispatch(setPaymentHistoryItems(25))
        this.props.dispatch(setPaymentHistoryRecordShow([1, 25]))
      }
      if (this.props.initialPaymentHistoryPage > 1) {
        this.resetCall(data);
      }
      // this.props.dispatch(
      //   getPaymentHistorytableList(
      //     this.props.initialPaymentHistoryPage - 1,
      //     this.props.paymentHistoryItems,
      //     payloadData,
      //     this.updateTableToDocs
      //   )
      // );
      // this.setState({ paymentType: "ALL", status: "" });
    }
    if (this.state.status === "") {
      this.setState({ statusError: true });
    } else {
      this.setState({ statusError: false });
    }
  }

  makemanual = () => {
    this.props.dispatch(setSnackbar(true));
    this.props.dispatch(setIntervalStopper(true));
  };

  DialogClose = () => {
    this.props.dispatch(setSnackbar(false));
    this.props.dispatch(setIntervalStopper(false));
    this.setState({
      amount: "",
      comments: "",
      adjustmentType: "",
      modelMoneyError: false,
      payment: "",
      isValid: true,
      offlinePayment: true,
    });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.onSubmit(event);
    }
  }
  componentDidMount() {
    const id = window.location.pathname.split("/")[2];
    const searchedData = {
      paymentType: this.state.paymentType,
      paymentApprovalType: this.state.option,
      userId: id,
      status: this.state.status,
    };
    if (!this.props.tdsPermission) {
      const v = this.state.headers.filter((key) => key !== "TDS Percentage" && key !== `TDS Amount ${Currency()}`)
      this.setState({ headers: v })
    }
    this.setState({ customerID: id });
    this.setState({ payloadData: searchedData });
    this.callApi(1, 25, searchedData)
    this.updateTableToDocs(this.state.paymentData);
    this.props.dispatch(setIsHistoryVisible(true));

  }
  componentDidUpdate(prevProps) {
    if (prevProps.paymentData !== this.props.paymentData) {
      this.updateTableToDocs(this.state.paymentData);
    }
    const id = window.location.pathname.split("/")[2];
    if (prevProps.userId !== id) {
      this.resetState();
      this.setState({ customerID: id });
      // const newPayload = { ...cleanObject(this.searchData()), id: id }
      this.callApi(1, 25, { userId: id })
    }
  }
  render() {
    const errorStyles = {
      color: "red",
      marginTop: "-13px",
      marginBottom: "20px",
    };

    const isPresent =
      Object.keys(this.props.userPagePermissions).length > 0 &&
      this.props.userPagePermissions.find(
        (item) => item.permissionName == "HIDE_MANUAL_ADJUSTMENT"
      );
    return (
      <>
        {
          this.props.snackbar && (
            <Dialog__Main
              title={"Balance Manual Adjustment"}
              dOpen={this.props.snackbar}
              dClose={() => this.DialogClose()}
              width={"sm"}
            >
              <ManualDialog
                availableAmount={
                  this.props?.financialData?.playerSummaryVw?.withdrawls
                }
                status={this.state.status}
                DialogClose={this.DialogClose}
                errorStyles={errorStyles}
                depositBalance={
                  this.props?.balanceData?.playerSummaryVw?.deposit
                }
                onSubmit={this.onSubmit}
              />
            </Dialog__Main>
          )
        }

        < div className="CMS-tabContent" >
          <div className="CMS-box-content">
            <div className="CMS-box CMS-table CMS-table-triped">
              <table className="CMS-tablePlain">
                {/* {Object.keys(this.props.financialData)} */}
                <tbody>
                  <tr>
                    <td>
                      <span>
                        {PYMENTHISTORY_CONSTANTS.header_cashBalance}
                        <b>
                          {Currency()} {/* past  */}
                          {/* {formatMoney(
                           Object.keys( this.props.balanceData).length > 0 && this.props.balanceData.playerSummaryVw.length > 0 
                              ? this.props.balanceData.playerSummaryVw
                                  ?.deposit

                              : "0"
                          )} */}
                          {/* Deposits Amount and Cash Balance are Same */}
                          {formatMoney(
                            this.props.showPayments &&
                              Object.keys(this.props?.financialData).length > 0
                              ? this.props?.financialData?.playerSummaryVw
                                ?.deposit +
                              this.props?.financialData.playerSummaryVw
                                ?.withdrawls
                              : 0
                          )}
                          {/* {formatMoney(
                                Object.keys(this.props.financialData).length > 0 && 
                                          this.props.financialData.playerSummaryVw
                                            .deposit
                                            ? this.props.financialData
                                                .playerSummaryVw.deposit
                                            : this.state.netDeposit
                            )} */}
                        </b>
                      </span>
                    </td>
                    <td>
                      <span className="d-flex justify-content-between">
                        <span>{PYMENTHISTORY_CONSTANTS.header_payments}</span>
                        {/* {formatMoney(
                        this.props?.paymentData.paymentEnabled
                          ? this.props?.paymentData.paymentEnabled
                          : "0"
                      )} */}
                        {/* </td>
                    <td> */}
                        <span
                          className={
                            this.props.playerData != undefined &&
                              this.props.playerData?.userOtherInfo?.paymentEnabled === true
                              ? "CMS-btnStatus CMS-statusActive pointer"
                              : "CMS-btnStatus CMS-statusClosed pointer"
                          }
                        >
                          {this.props.playerData != undefined &&
                            this.props.playerData?.userOtherInfo?.paymentEnabled === true
                            ? "ENABLED"
                            : "DISABLED"}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span>{PYMENTHISTORY_CONSTANTS.header_depoLimits}</span>
                      <span>
                        <b>
                          {this.props?.userinfo?.userLimit
                            ? this.props.userinfo?.userLimit?.depositLimit
                            : 0}
                        </b>
                      </span>
                    </td>
                    <td>
                      <span>{PYMENTHISTORY_CONSTANTS.header_max}</span>
                      <span>
                        <b>
                          {/* {this.props.showPayments &&
                          !["NA", undefined, null].includes(
                            this.props.userinfo?.userLimit.depositLimit
                          )
                            ? this.props.userinfo?.userLimit.depositLimit
                            : " -"} */}
                          {this.props.userinfo?.userLimit
                            ? this.props.userinfo?.userLimit?.depositLimit
                            : 0}
                        </b>
                      </span>
                    </td>
                    <td>
                      <span>{PYMENTHISTORY_CONSTANTS.header_creditTotal}</span>
                      {/* {Object.keys(this.props?.financialData).length > 0 && this.props?.financialData.playerSummaryVw !==
                        undefined && ( */}
                      <span>
                        <b>
                          {Currency()}{" "}
                          {formatMoney(
                            this.props.showPayments &&
                              Object.keys(this.props.financialData).length >
                              0 &&
                              this.props.financialData?.playerSummaryVw
                                ?.withdrawls
                              ? this.props.financialData?.playerSummaryVw
                                ?.withdrawls
                              : 0
                          )}
                          {/* {formatMoney(
                              Object.keys(this.props?.financialData).length > 0 ?  
                              this.props?.financialData.playerSummaryVw
                                ?.netWithdrawal +
                                this.props.balanceData.playerSummaryVw.deposit : this.state.netWithdrawal + this.state.netDeposit
                            )} */}
                        </b>
                      </span>
                      {/* )} */}
                    </td>
                    <td>
                      <span>{PYMENTHISTORY_CONSTANTS.header_debitTotal}</span>
                      <span>
                        <b>
                          {Currency()}{" "}
                          {formatMoney(
                            this.props.showPayments &&
                              Object.keys(this.props.financialData).length >
                              0 &&
                              this.props.financialData?.playerSummaryVw
                                ?.netWithdrawal
                              ? this.props.financialData?.playerSummaryVw
                                ?.netWithdrawal
                              : 0
                          )}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="CMS-select">
                        <select
                          value={this.state.option}
                          id="option"
                          onChange={(e) => this.onChangeOption(e)}
                          onKeyPress={this.onKeyUp}
                          className="pointer"
                        >
                          <option value="ONLINE">{PYMENTHISTORY_CONSTANTS.ddlOpt_auto}</option>
                          <option value="MANUAL">{PYMENTHISTORY_CONSTANTS.ddlOpt_manual}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="CMS-select">
                        <select
                          value={this.state.paymentType}
                          id="paymentType"
                          onChange={(e) => this.onChangePaymentHistory(e)}
                          onKeyPress={this.onKeyUp}
                          className="pointer"
                        >
                          <option value="ALL">{PYMENTHISTORY_CONSTANTS.ddlOpt_all}</option>
                          <option value="WITHDRAW">{PYMENTHISTORY_CONSTANTS.ddlOpt_withDrwal}</option>
                          <option value="DEPOSIT">{PYMENTHISTORY_CONSTANTS.ddlOpt_deposit}</option>
                        </select>
                      </div>
                    </td>
                    {this.state.option !== "ONLINE" &&
                      this.state.paymentType !== "ALL" && (
                        <td>
                          <div className="CMS-select">
                            <select
                              id="adjustmentType"
                              name="adjustmentType"
                              onChange={(e) => this.onChangeAdjustment(e)}
                              className="pointer"
                              value={this.state.adjustmentType}
                            >
                              <option value="">{COMMON_CONSTANTS.ddlOpt_select}</option>
                              {manualDropdown.map((item, index) => {
                                return this.state.paymentType === "DEPOSIT" &&
                                  item.isDeposit ? (
                                  <option value={item.value}>
                                    {item.name}
                                  </option>
                                ) : (
                                  this.state.paymentType === "WITHDRAW" &&
                                  !item.isDeposit && (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  )
                                );
                              })}
                            </select>
                          </div>
                        </td>
                      )}

                    <td>
                      <div className="CMS-select">
                        <select
                          value={this.state.status}
                          id="paymentstatus"
                          onChange={this.onChangeStatus}
                          onKeyPress={this.onKeyUp}
                          className="pointer"
                        >
                          <option value="">{KYC_CONSTANTS.header_status}</option>
                          <option value="ALL">{PYMENTHISTORY_CONSTANTS.ddlOpt_all}</option>
                          <option value="INIT">{PYMENTHISTORY_CONSTANTS.ddlOpt_init}</option>
                          <option value="APPROVED">{PYMENTHISTORY_CONSTANTS.ddlOpt_approved}</option>
                          <option value="CONFIRMED">{PYMENTHISTORY_CONSTANTS.ddlOpt_confirmed}</option>
                          {/* <option value='ACCEPT'>ACCEPT</option> */}
                          <option value="DECLINED">{PYMENTHISTORY_CONSTANTS.ddlOpt_decline}</option>
                          <option value="CANCELLED">{PYMENTHISTORY_CONSTANTS.ddlOpt_cancelled}</option>
                          <option value="VERIFIED">{PYMENTHISTORY_CONSTANTS.ddlOpt_verified}</option>
                          <option value="HOLD">{PYMENTHISTORY_CONSTANTS.ddlOpt_hold}</option>
                        </select>
                      </div>
                      {this.state.statusError && (
                        <div className="CMS-form-errorMsg">
                          Please select status
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="CMS-btnContainer">
                        <button
                          className="CMS-btn CMS-btnSecondary active CMS-btnMedium "
                          style={{
                            paddingLeft: "35px",
                            paddingRight: "35px",
                            marginRight: "20px",
                            marginLeft: "20px",
                          }}
                          type="button"
                          onClick={this.onSearchRecord}
                          id="paymentsearch"
                        >
                          {COMMON_CONSTANTS.searchBtn}
                        </button>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="CMS-btnContainer">
                            <button
                              className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                              type="button"
                              id="paymentreset"
                              onClick={this.resetButton}
                              style={{
                                paddingLeft: "35px",
                                paddingRight: "35px",
                                marginRight: "20px",
                                marginLeft: "20px",
                              }}
                            >
                              {COMMON_CONSTANTS.resetBtn}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    {!isPresent
                      && (
                        <td>
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                              <div className="CMS-btnContainer">
                                <button
                                  className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${this.props.viewOnlyuser ? "disable-button" : ''}`}
                                  type="button"
                                  id="makemanualadjust"
                                  onClick={() => this.makemanual()}
                                >
                                  {PYMENTHISTORY_CONSTANTS.manualAdjustmentBtn}
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      )}
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
            <div className="mt-20"></div>
            <div className="CMS-filter-result" id="result">
              <div className="CMS-pagination">
                <Vpagination
                  data={this.props.paymentHistoryUserData.data}
                  totalRecords={this.props.paymentHistoryUserData.totalRecords}
                  paginationFirstValue={this.props.initialPaymentHistoryPage}
                  paginationSecondValue={this.props.paymentHistoryItems}
                  setPaginationFirstValue={setInitialPaymentHistoryPage}
                  setPaginationSecondValue={setPaymentHistoryItems}
                  recordsShow={this.props.paymentHistoryRecordShow}
                  setRecordsShow={setPaymentHistoryRecordShow}
                  isVisible={this.props.isHistoryVisible}
                  setIsVisible={setIsHistoryVisible}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"PaymentHistory"}
                  payloadData={this.state.payloadData}
                />
              </div>
              {!this.props.isHistoryVisible ? (
                <Skelton />
              ) : (
                <PaymentHistoryTable
                  data={
                    this.state.condition
                      ? this.state.paymentFilterData
                      : this.props.paymentHistoryUserData.data
                  }
                  tdsExist={this.props.tdsPermission}
                />
              )}
            </div>
          </div>
        </div >
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    paymentData: state.paymentHistorySlice.paymentData,
    initialPaymentHistoryPage: state.paymentHistorySlice.initialPaymentHistoryPage,
    paymentHistoryItems: state.paymentHistorySlice.paymentHistoryItems,
    snackbar: state.paymentHistorySlice.snackbar,
    paymentHistoryRecordShow: state.paymentHistorySlice.paymentHistoryRecordShow,
    userId: state.playersearch.userId,
    addmakeid: state.paymentHistorySlice.addmakeid,
    paymentData: state.paymentHistorySlice.paymentData,
    userinfo: state.paymentHistorySlice.userinfo,
    financialData: state.primaryplayer.financialData,
    balanceData: state.primaryplayer.balanceData,
    isHistoryVisible: state.paymentHistorySlice.isHistoryVisible,
    showPayments: state.paymentHistorySlice.showPayments,
    playerData: state.paymentHistorySlice.playerData,
    playerData: state.generaldetail?.playerData,
    userPagePermissions: state.dashboard.userPagePermissions,
    tdsPermission: state.dashboard.tdsPermission,
    paymentHistoryUserData: state.paymentHistorySlice.paymentHistoryUserData,
    viewOnlyuser: state.dashboard.viewOnlyuser,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);

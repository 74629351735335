import { deleteKycReport } from '../components/KYCDocuments/KycDocSlice'
import { getBaseUrl } from './helper'
var endPoint = process.env.REACT_APP_UPS_API_URL
var devBonusPoint = process.env.REACT_APP_PBS_API_URL
var cyrusEndPoint = process.env.REACT_APP_CYRUS
var biReportLink = process.env.REACT_APP_BIREPORT_LINK
var pnsEndPoint = process.env.REACT_APP_PNS_API_URL
let baseUrl = ''
export const setBaseUrl = () => {
  baseUrl = getBaseUrl(cyrusEndPoint, endPoint)
}
export const playerapi = {
  getPlayer: endPoint + 'player',
  getPlayerSearch: endPoint + 'player/search',
  getGeneralDetail: endPoint + 'player/',
  getLoginHistory: endPoint + 'player/',
  getAddLoginHistory: endPoint + 'player/',
  getPaymentHistory: endPoint + 'player/',
  getAccountHistory: endPoint + 'player/',
  getPlayerBetHistory: endPoint + 'bet/',
  getNewPlayer: endPoint + 'player/newplayersearch',
  // getBetHistory: endPoint + "player/bethistory",
  getPlayerActivity: endPoint + 'player/playeractivity',
  getActivePlayer: endPoint + 'player/activeplayers',
  getAddNotes: endPoint + 'player/',
  getNotes: endPoint + 'player/notes',
  getMakeManual: endPoint + 'player/',
  getUpdatePlayer: endPoint + 'player/',
  getBonuswidget: endPoint + 'player/',
  addTags: endPoint + 'player/',
  fraudScrub: (userId) => `${endPoint}player/fraud/${userId}`,
  fraudScrubIp: (ip) => `${endPoint}player/fraud/${ip}/players/`,
  getNotifications: `${endPoint}player/payments/count`,
  getActivateDeactivate: (id) => endPoint + `player/${id}`,
  getUserStakePreference: (id) => endPoint + `player/${id}/stake/preference`,
  saveUserStake: (id) => endPoint + `player/${id}/stake/preference`,
  playerReferral: endPoint + "admin/report/referral",
  getRegistrationTicker: (pageNum, itemPerPage) => endPoint + `player/registration/ticker?pageNumber=${pageNum}&itemsPerPage=${itemPerPage}`,
  getTags: endPoint + `/platformtag/old/get/allTags`,
  kycDocuments: (userId, pageNum, itemsPerPage) => endPoint + `kyc/${userId}?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  deleteKycReport: (id) => endPoint + `kyc/delete/doc/${id}`,
}
export const dashboardApi = () => {
  return {
    getLoosers: baseUrl + 'dashboard/loosers',
    getTurnover: baseUrl + 'dashboard/turnover',
    getWinner: baseUrl + 'dashboard/winners',
    getProfitSports: baseUrl + 'dashboard/profitsports',
    getRevenuBrand: baseUrl + 'dashboard/stakerevenue',
    getRevenuSport: baseUrl + 'dashboard/sportsrevenue',
    getPreMatchTurnover: baseUrl + 'dashboard/turnoverprematch',
    getInplayTurnover: baseUrl + 'dashboard/turnoverinplay',
    getTurnoverMain: baseUrl + 'dashboard/turnovermain',
    getHighestTurnover: baseUrl + 'dashboard/highturnoversports',
    getRevenueBySport: baseUrl + 'dashboard/revenuesports',
  }
}

export const playerapicountry = {
  getPaymentTicker: endPoint + 'wallet/getAllTransactions',
}

export const loginapi = {
  getLogin: endPoint + 'platform/user/login',
}

export const bonusApi = {
  getBonus: devBonusPoint + 'bonus/create',
  getBonusummery: devBonusPoint + 'bonus/all',
  updateBonus: devBonusPoint + 'bonus/update',
  getPlayerBonus: devBonusPoint + 'bonus/',
  updateBonusCode: devBonusPoint + 'bonus/allocate',
  getBonuswidget: devBonusPoint + 'bonus/',
  getSystemBonus: (from) => devBonusPoint + `bonus/system/summary?day=${from}`,
  fetchAllBonus: (data) =>
    devBonusPoint +
    `bonus/all?bonusType=${data.bonusType}&bonusStatus=${data.statusType}&pageNumber=${data.pageNumber}&itemsPerPage=${data.itemsPerPage}`,
  getBonusData: (data) =>
    devBonusPoint +
    `bonus/all?pageNumber=${data.pageNumber}&itemsPerPage=${data.itemsPerPage}`,
  fetchSports: devBonusPoint + 'bonus/sports',
  bulkUpload: devBonusPoint + 'bonus/allocatebulk',
  MultibetConfig: devBonusPoint + 'multibet',
  MultibetAccount: devBonusPoint + 'multibet/transactions',
  getCouponCode: devBonusPoint + 'bonus/couponcode',
  getCouponCode: devBonusPoint + 'bonus/couponcode',
  getWithdrawBonus: devBonusPoint + 'bonus/withdraw',
  bonusTicker: devBonusPoint + 'bonus/bonus-ticker',
  allowTax: devBonusPoint + 'bonus/getTags',
  providerName: (provider) => endPoint + `bet/bet/providerName?providerNames=${provider}`,
  bonusAwardDetails: (id, pageNum, itemsPerPage) => devBonusPoint + `bonus/${id}/bonus-awarded?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  getAffiliates: devBonusPoint + 'bonus/affiliates',
}
export const brandApi = {
  getBrand: endPoint + 'brand',
  getBrandConfig: endPoint + 'brand/configs',
  getByBrand: ({ pageNum, noItems }) =>
    endPoint + `brandkey/all?pageNumber=${pageNum}&itemsPerPage=${noItems}`,
  addBrandKey: endPoint + 'brandkey',
  getUpdateKey: (id) => endPoint + `brandkey/${id}`,
  getAllBrand: endPoint + 'brand/all',
  brandStatus: (id, status) => `${endPoint}brand/${id}?status=${status}`,
  getSmsProviderList: endPoint + 'config/details/sms/providers',
  getProviderList: (msgType) => `${endPoint}config/details/providers?messageType=${msgType}`
}
export const paymentSearchApi = {
  getPaymentSearch: endPoint + 'player/payment/history',
  getPaymentTickerData: endPoint + 'player/payment/ticker',
  getPendingWithrawals: endPoint + 'player/withdraw',
  getPendingDeposit: endPoint + 'player/payment/images',
  getUpdateDeposit: endPoint + 'player/payment/image',
  getNewPendingWithrawals: endPoint + 'player/withdrawal',
  fetchPedingWithdrawals: ({ pageNum, noItems }) =>
    endPoint +
    `player/withdrawal/bank?pageNumber=${pageNum}&itemsPerPage=${noItems}`,
  fetchPendingWithdrawalsStatus: ({ status, pageNum, noItems }) =>
    endPoint +
    `player/withdrawal/bank?status=${status}&pageNumber=${pageNum}&itemsPerPage=${noItems}`,
  updateWithdrawal: (id) => endPoint + `player/withdraw/${id}`,
  paymentLimitsUrl: endPoint + 'config/details/payment',
  paymentProviders: endPoint + "player/payment/providers",
  playerPaymentLimit: endPoint + "player/payment/limits/update",
  getUserPaymentLimit: (id) => endPoint + `player/payment/limits/${id}`,
  getEventsListById: (tnxId) => endPoint + `player/payment/${tnxId}`,
  updatePaymnetProviderSts: endPoint + "player/payment/providers/update",
  requeastDepoProof: (tnxId) => endPoint + `player/payment/deposit-proof/request?transactionId=${tnxId}`,
  getDepositProof: endPoint + "player/payment/deposit-proof/list",
  acceptDepoProof: (id, comments) => endPoint + `player/payment/deposit-proof/accept?id=${id}&comments=${comments}`,
  rejectDepoProof: (id, comments) => endPoint + `player/payment/deposit-proof/reject?id=${id}&comments=${comments}`,
  cancelWithdrawa: (txnId) => endPoint + `player/${txnId}/cancel/withdraw`,
  UserCashoutAvailability: endPoint + "player/payment/cashout/update"
}

export const bettingApi = () => {
  return {
    getBettingTicker: baseUrl + 'bet/ticker',
    getBetHistoryApi: baseUrl + 'bet/history',
    betId: baseUrl + 'bet/',
    getBetLiability: endPoint + 'bet/liability',
    getCasinoDropdowns: (type) => endPoint + `bet/extraInfo/type?type=${type}`
  }
}

export const tagApi = {
  getTag: endPoint + 'tags',
  postTag: endPoint + 'player/',
  getAllUserTags: endPoint + 'platformtag/get/allTags',
  // deactiveTag: (userTagId) => endPoint + `player/update/${userTagId}/status`,
  uplaodAssignTags: endPoint + 'player/assign/tag/bulk',
  activeTags: endPoint + 'player/update/user/tag/status',
}

export const reportApi = {
  getReport: endPoint + 'admin/reports',
  geMultipleDepositReport: endPoint + 'admin/report/deposit',
  referFriend: endPoint + "admin/report/referral",
  getWithdrawReport: endPoint + "admin/report/transaction/withdraw",
  getDepositReport: endPoint + "/admin/report/transaction/deposit",
  getPlaceBetReport: endPoint + "/admin/report/bet/gst",
  liabiltyEventReport: endPoint + "admin/report/liability/event",
  liabiltyMarketReport: endPoint + "admin/report/liability/market",
}

export const kycApi = {
  getKyc: endPoint + 'kyc/',
  getKycType: endPoint + 'kyc/type',
  getKycApproval: endPoint + 'kyc/approve/all',
  getkycRejected: endPoint + 'kyc/reject/all',
  playerKycReject: endPoint + 'kyc/update',
  requestKyc: (id) => endPoint + `kyc/kyc/request/${id}`,
}
export const bankApi = {
  getBank: endPoint + 'player/bank/details/',
  activeBank: endPoint + 'player/bank/details/active/',
  setPrimary: (id) => endPoint + `player/${id}/bank/details/`,
}

export const configApi = {
  getConfig: endPoint + 'config/details',
  getConfigValues: endPoint + 'config/details/values',
}

export const userApi = {
  platformUserProfile: endPoint + 'platform/user/profile',
  getPermission: endPoint + 'role/permissions',
  createPermission: endPoint + 'role/create',
  getRoleList: endPoint + 'role/list',
  getAssignRole: endPoint + 'role/assign',
  getAdminUser: endPoint + 'platform/user/list',
  createUser: endPoint + 'platform/user',
  getAllRoles: (brandId) => endPoint + `platform/user/roles/${brandId}`,
  getUserPermission: endPoint + 'platform/user/permission',
  getPermissionCreate: endPoint + 'role/permission/create',
  getRolePermission: endPoint + 'role/permissions',
  editRole: (id) => endPoint + `role/${id}`,
  createAffiliate: endPoint + 'platform/agent/add',
  getAffiliate: endPoint + 'platform/agent',
  addCampaign: (id) => `${endPoint}platform/agent/campaign/${id}`,
  listCampaign: (id, page, items) =>
    `${endPoint}platform/agent/list/campaign/${id}?pageNumber=${page}&itemsPerPage=${items}`,
  addChannel: (id) => `${endPoint}platform/agent/channel/${id}`,
  listChannels: (page, items) =>
    `${endPoint}platform/agent/channel?pageNumber=${page}&itemsPerPage=${items}`,
  listCapaignURL: (capaignId) =>
    `${endPoint}platform/agent/campaign/urls/${capaignId}`,
  totpDetails: endPoint + 'platform/user/totp',
  totpLogin: endPoint + 'platform/user/login/totp',
  resetPassword: (userId) => endPoint + `platform/user/${userId}/password/reset`,
  forcePasswordChange: endPoint + "platform/user/password/reset/force",
  updateUserDetails: (userId) => `${endPoint}platform/user/${userId}`,
  artworkUpload: endPoint + "platform/agent/documents/affiliate",
  getArtwork: endPoint + `platform/agent/documents/affiliate`,
  getAffSummaryDetail: endPoint + "platform/agent/affiliate/user/details",
  refreshToken: endPoint + "platform/user/refreshToken",
  failedData: endPoint + "dashboardv2/failed-report-by-type"
}

export const resetPasswordApi = {
  getResetPassword: (id) => endPoint + `player/${id}/password/reset`,
}

export const kycUploadEndPoint = {
  uploadKYC: (idType, kycType, userId) => endPoint + `kyc/upload?idType=${idType}&kycType=${kycType}&userId=${userId}`,
}

export const roleDeleteApi = {
  getRoleDelete: endPoint + 'role/delete',
}
export const betApi = {
  getBetAudit: endPoint + 'bet/',
}

//dev.platform-ups.sportsit-tech.net:443/dashboard/operational?from=THIS_MONTH
export const operationsApi = {
  opTable: (from) => endPoint + `dashboard/operational?from=${from}`,
}

export const clientApi = {
  getClient: endPoint + 'client',
}

export const currencyConversionApi = {
  getCurrency: endPoint + 'currency',
}

export const ResponsibleGamblingApi = {
  updateUserLimits: endPoint + '/player/limits/',
  getPlayertLimit: (playerid) => endPoint + `player/limits/${playerid}`
}

export const riskManagement = {
  getFraudScrubIPs: endPoint + `fraudscrub/ips`,
  getFraudScrubUsersByIP: (ip, pageNum, itemsPerPage) => endPoint + `fraudscrub/${ip}/players/?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  getFraudIPAndMacAddress: (pageNum, itemsPerPage) => endPoint + `fraudscrub/ipAndMacaddress?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  getUsersByIPAndMacAddress: (pageNum, itemsPerPage) => endPoint + `fraudscrub/ipAndMacaddress/players?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  getSyndicateBetting: endPoint + `fraudscrub/syndicateBetting`,
  getPlayerbets: (selectionId, selectionName, eventName, stake, pageNum, itemsPerPage) => endPoint + `fraudscrub/syndicateBetting/${selectionId}/${selectionName}/${eventName}/${stake}/playerbets?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`,
  arbitageBet: (pageNum, itemsPerPage) => endPoint + `fraudscrub/playersDetailsBySameProfileV1?pageNumber=${pageNum}&itemPerPage=${itemsPerPage}`,
  dupeCids: endPoint + "fraudscrub/playersDetailsBySameProfileV2"
}
export const biReportLinks = {
  ggrMonthlyRevenue: biReportLink + "?reportId=8135ca9b-262e-44dd-ac5b-a16b3d03d795&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection6d7395b18c4a1d7c5b58",
  ggrDailyRevenue: biReportLink + "?reportId=d7ed9dd7-1a66-47dc-aa47-7b131ec13188&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection",
  ggrDailyRevGameworx: biReportLink + "?reportId=d7ed9dd7-1a66-47dc-aa47-7b131ec13188&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection02fca194dce0030ebc66",

  sportLeague: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection",
  sportEventMarket: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection6acc79d57772626b4443",
  sportMonthlyBetCount: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&pageName=ReportSectiond2bc17a5049820b7c070",
  sportDailyBetCount: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection4b103f4b52eb56048cb5",
  sportBetfoldDaily: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&pageName=ReportSectionaff473bae699d4ca5bdb",
  sportProfitByEvent: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&pageName=ReportSection552cee58900756b9a3ae",
  sportBetfoldMonthly: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSectionbb9502bcb76cca8d4cb0",
  trailing7DayCashStakes: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection51a51dda6c6c36acae1e",
  cashStakesMarketOverview: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection7fe52e43dce1361b23d0",
  highestProfitLoss: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSectiondf80f2e3e605dd9e7910",
  // sportRevenueByMarket: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",

  userDailyBettingChannel: biReportLink + "?reportId=9552867e-3c90-4328-b10f-8020597b10fb&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection",
  userMonthlyBettingChannel: biReportLink + "?reportId=9552867e-3c90-4328-b10f-8020597b10fb&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection649d029d761fe1c0005a",
  userBettingChnlGameworx: biReportLink + "?reportId=9552867e-3c90-4328-b10f-8020597b10fb&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection37730dd0465f0ce9adfc",
  bonusReporting: biReportLink + "?reportId=28d8d806-53c1-4ac2-9326-2570dcc256fa&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",

  gamesDailySummary: biReportLink + "?reportId=b45ac660-24b1-4051-a8f2-250c3d982cc6&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection6d8fcdf740000fdf6d9e",
  gamesTopCustomers: biReportLink + "?reportId=9a56e65b-0be1-4390-8987-f40fb66a155a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection82ffdaf7b881b47d66e5",
  gamesDaily: biReportLink + "?reportId=9a56e65b-0be1-4390-8987-f40fb66a155a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection33cb23579320750c4990",
  gamesCustomerBetsDaily: biReportLink + "?reportId=9a56e65b-0be1-4390-8987-f40fb66a155a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection6c93499fbdc568e8c19e",

  averageDailyDeposit: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection77ff234084d1d55a427e",
  averageMonthlyDeposit: biReportLink + "?reportId=c2e4d197-c5c1-4711-ab25-706a86772c9b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSectionaae095cbbedc028c8729",
  weeklyUserAnalysis: biReportLink + "?reportId=b7d1b8da-a1c9-4bf4-aa05-808bb1508126&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",

  monthlyStateSales: biReportLink + "?reportId=59a95453-0e8c-4380-b1e4-ac48521b255a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  dailyStateSales: biReportLink + "?reportId=59a95453-0e8c-4380-b1e4-ac48521b255a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection4714c5e357471584e86b",
  staKe10K_NGN: biReportLink + "?reportId=59a95453-0e8c-4380-b1e4-ac48521b255a&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033&pageName=ReportSection498aee0b6a2a015e316d",
  bettingHistoryReport: biReportLink + "?reportId=0ffd000e-eb78-48c1-8acf-b56918621c7b&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",

}

export const biDashboardLink = {
  overViewReport: biReportLink + "?reportId=0e676f17-6061-4488-9eb7-a2cbdec453d5&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  sportsOverViewReport: biReportLink + "?reportId=72de9ddb-c33e-4fd2-a04b-7221a0c1d6fa&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  sportsOverViewAmountsReport: biReportLink + "?reportId=2835c854-ee22-4a8d-94cb-11702173b449&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  casinoOverViewReport: biReportLink + "?reportId=d094d6d2-bed3-4cc1-b484-70b21f1c2e30&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  gamesOverViewReport: biReportLink + "?reportId=ba190253-9bfc-4ba7-a3cf-6b11ae7c3ff3&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
  financeOverViewReport: biReportLink + "?reportId=20bf29fe-e535-49b9-b7d2-1d71440ab268&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033",
}

export const biVipLink = {
  vipReportProd: biReportLink + '?reportId=2abd7746-8555-4f74-b2d0-e6d32f1f04eb&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033',
  vipReportDev: biReportLink + '?reportId=cc3ee037-4dbc-4aef-b105-55baa8211926&autoAuth=true&ctid=c6ce5f7c-8c88-434f-bebb-b8103aeff033',
}

export const browserPushApis = {
  userPushNotificationSubscribe: pnsEndPoint + "astra/user/notifications/push/subscribe",
  userPushNotificationUnSubscribe: pnsEndPoint + "astra/user/notifications/push/unsubscribe"
}

export const communicationApis = {
  getPromotionList: pnsEndPoint + "promotions/list",
  savePromotionDtl: pnsEndPoint + "promotions",
  getNotyTemplateByType: pnsEndPoint + "promotions/notification/templates",
  updateNotyTemplate: pnsEndPoint + "promotions/notification/templates",
  updateWelcomeNotyTemplate: pnsEndPoint + "promotions/notification/templates/welcome",
  editPromotionList: (id) => pnsEndPoint + `promotions/${id}`
}

export const boostedOddsApis = {
  //DDL APIS//
  getAllSportsByBrand: endPoint + 'feed/sports',
  getAllCountriesBySport: (sportsId) => endPoint + `feed/countries?sportsId=${sportsId}`,
  getLeaguesBysportAndCountry: (sportId, countryId) => endPoint + `feed/leagues?sportsId=${sportId}&countryId=${countryId}`,
  getEventsBySportCountryLeague: (sport, country, league) => endPoint + `feed/events?sportsId=${sport}&countryId=${country}&leagueId=${league}`,
  getAllMarketsByEvent: (eventId) => endPoint + `feed/markets?eventId=${eventId}`,
  getSubMarketByMarketEvent: (event, market) => endPoint + `feed/subMarkets?eventId=${event}&marketId=${market}`,
  getOutcomesByMarketOrSubmarket: (event, market) => endPoint + `feed/outComes?eventId=${event}&marketId=${market}`, //url changes handled in slice file
  getOddsByOutcomeId: (event, market) => endPoint + `feed/odds?eventId=${event}&marketId=${market}`,//url changes handled in slice file

  //CREATE UPDATE APIS//
  createBoostedOdds: endPoint + "boostedodds/create",
  getAllBoostedOdds: (pageNum, itemsPerPage) => endPoint + `boostedodds/list/all?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`, //TABLE API
  updateBoostedOddsStatus: endPoint + "boostedodds/update/status", //ACTIVE DEACTIVE UPDATE API
  boostedOddsSearchApi: endPoint + "boostedodds/search", //SEARCH API
  viewBoostedOddsUsers: (id) => endPoint + `boostedodds/view/users?boostedOddsId=${id}`
}

export const userWatchListApis = {
  addToUserWatchList: endPoint + "player/watch-list/add", //individual player comment
  getUserWatchList: (pagenum, itemsPerPage) => endPoint + `player/watch-list?pageNumber=${pagenum}&itemPerPage=${itemsPerPage}`, // main watchlist screen

  //ACTIVITIES FOR WATCHLIST USERS 
  getCommentHistory: (userId, pageNum, itemsPerPage) => endPoint + `player/watch-list/comments/history/${userId}?pageNumber=${pageNum}&itemPerPage=${itemsPerPage}`,
  getWatchUsersActivity: (pageNum, itemsPerPage) => endPoint + `player/watch-list/activities?pageNumber=${pageNum}&itemPerPage=${itemsPerPage}`,
  removeUserWatchList: (userId) => endPoint + `player/watch-list/remove/${userId}`,

  //CREATE WATCHLIST APIS
  addAutomatedWatchlist: endPoint + "player/watch-list/auto/config/add",
  getAutomatedWatchlist: (pageNum, itemsPerPage) => endPoint + `player/watch-list/auto/config?pageNumber=${pageNum}&itemPerPage=${itemsPerPage}`,
  getBetTypes: endPoint + `/player/watch-list/auto/config/betTypes`
}

export const newDashboardApis = {
  getOverallReviewReportByPeriod: endPoint + "dashboardv2/overall/review/report",
  getSportsBetsReport: endPoint + "dashboardv2/sports/bets/report",
  getBetReportByGameType: endPoint + "dashboardv2/bet/reports/by/game/type",
  getBonusReport: endPoint + "dashboardv2/bonus/report",
  getPaymnetReport: endPoint + "dashboardv2/payments/report",
  getUserActivityReport: endPoint + "dashboardv2/user/activity/report",
  getUserActiveTimesReportByType: (type) => endPoint + `dashboardv2/user/active/times/report?type=${type}`,
  getTopTenWithdrawableUsers: endPoint + "dashboardv2/top/withdrawable/balance/users",
  getTopTenWinnerLooser: endPoint + "dashboardv2/top/winners/losers/report",
  getTopSportsGamesReport: endPoint + "dashboardv2/top/sports/games/report",
}

export const CyrusUIApis = {
  getBetSettlement: endPoint + `cyrus/ui/search/all/bets`,
  viewTransaction: endPoint + `cyrus/ui/bet/transactions`,
  syncBet: endPoint + `cyrus/ui/bet/sync`,
  betDeatils: endPoint + `cyrus/ui/bet/selections`,
  rollBack: endPoint + `cyrus/ui/bet/rollback`,
  manualSettlement: endPoint + `cyrus/ui/bet/manual/settlement`,
  viewBetDetails: endPoint + `cyrus/ui/view/astra/bet/history`,
  viewAstra: endPoint + `cyrus/ui/view/astra/bet/selection`
}

export const Combisuggestions = {
  createAccumulatorConfig: endPoint + "accumulator/create",
  getAccumulator: (pageNum, itemPerpage) => endPoint + `accumulator/list/all?pageNumber=${pageNum}&itemsPerPage=${itemPerpage}`,
  getConfigByID: (id) => endPoint + `accumulator/config/selections/${id}`,
  configEdit: endPoint + "accumulator/update/status",
  searchConfig: endPoint + 'accumulator/search',
  viewUser: (accumulatorId) => endPoint + `accumulator/view/users?accumulatorId=${accumulatorId}`
}
export const TaxationApis = {
  putSportsDetails: endPoint + 'bet/tax/config/sports',
  getSportsDetails: (type, provider) => {
    let url = `${endPoint}bet/tax/config/sports?type=${type}`;
    if (provider) {
      url += `&provider=${provider}`;
    }
    return url;
  }
}
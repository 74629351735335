import React, { useEffect, useCallback, useMemo, useState } from "react";
import Select from "../Common/common_inputs/Select_DDL";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";
import Tooltips from "../Common/Tooltip";
import Input from "../Common/common_inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSportsData,
  saveSportstax,
  setGamesFilter,
  setGamesLimits,
  setGamesResetLimit,
  setGamesFormData,
  setHasChanged,
} from "./TaxationSlice";
import { LimitData, limitsData, gamesType } from "../Common/data/mapData";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { cleanPayload, regexType } from "../../sharedfiles/helper";

const GamesTaxation = () => {
  const [gameTypeLimits, setGameTypeLimits] = useState({
    CASINO: [],
    LOTTERY: [],
    NE_GAMES: [],
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const dispatch = useDispatch();
  const {
    gamesData,
    gamesFilter,
    gamesLimits,
    isSportsDisabled,
    gamesResetLimit,
    gamesFormData,
    hasChanged,
    gamesDataHolder,
  } = useSelector((state) => state.taxation);

  useEffect(() => {
    if (gamesData?.length > 0) {
      const data = gamesData[0];
      dispatch(
        setGamesFormData({
          stakeTax: data?.stakeTaxPercentage || 0,
          winningsTax: data?.winningsTaxPercentage || 0,
        })
      );
    }
  }, [gamesData, dispatch]);

  useEffect(() => {
    if (gamesFilter.gameType) {
      const allLimitValues = LimitData.map((option) => option.value);

      if (isInitialLoad || !gameTypeLimits[gamesFilter.gameType]?.length) {
        dispatch(setGamesLimits(allLimitValues));
        setGameTypeLimits((prevLimits) => ({
          ...prevLimits,
          [gamesFilter.gameType]: gamesLimits,
        }));
      } else {
        dispatch(setGamesLimits(gameTypeLimits[gamesFilter.gameType]));
      }

      dispatch(setGamesResetLimit(true));
      setIsInitialLoad(false);
    }
  }, [gamesFilter.gameType, gameTypeLimits, dispatch, isInitialLoad]);

  const handleGameType = useCallback(
    (event) => {
      const selectedGameType = event.target.value;
      dispatch(setGamesResetLimit(true));
      dispatch(setGamesFilter({ gameType: selectedGameType, provider: "" }));
      dispatch(setGamesLimits(gameTypeLimits[selectedGameType] || []));
      if (selectedGameType) {
        dispatch(fetchSportsData(selectedGameType, ""));
      }
    },
    [dispatch]
  );

  const handleLimit = useCallback(
    (value) => {
      setGameTypeLimits((prevLimits) => ({
        ...prevLimits,
        [gamesFilter.gameType]: value,
      }));
      dispatch(setGamesLimits(value));
    },
    [gamesFilter.gameType, dispatch]
  );

  const handleChange = useCallback(
    (event, field) => {
      const value = event.target.value;
      dispatch(setGamesFormData({ [field]: +value }));
    },
    [dispatch]
  );

  const onSave = useCallback(async () => {
    let payload = {
      gameType: gamesFilter.gameType,
      id: gamesData[0].id,
    };

    if (gamesLimits.includes("Stake_Tax"))
      payload.stakeTaxPercentage = gamesFormData.stakeTax;
    if (gamesLimits.includes("Winning_Tax"))
      payload.winningsTaxPercentage = gamesFormData.winningsTax;

    const cleanedPayload = cleanPayload(payload);
    dispatch(setHasChanged(true));
    setTimeout(() => {
      dispatch(setHasChanged(false));
    }, 3000);
    await dispatch(saveSportstax(cleanedPayload));
    await dispatch(fetchSportsData(gamesFilter.gameType, ""));

    // if (gamesData?.length > 0) {
    //     const updatedData = gamesData[0];
    //     dispatch(
    //         setGamesFormData({
    //             stakeTax: updatedData?.stakeTaxPercentage || 0,
    //             winningsTax: updatedData?.winningsTaxPercentage || 0,
    //         })
    //     );
    // }
  }, [gamesFilter, gamesFormData, gamesLimits, gamesData, dispatch]);

  return (
    <React.Fragment>
      <div className="CMS-tab-panel active" id="CMS-playerActivity">
        <div className="CMS-tabContent">
          <div className="CMS-box CMS-box-content">
            <div className="row mb-10">
              <div className="col-12">
                <div className="CMS-cardBox" style={{ paddingBottom: "20px" }}>
                  <div className="row">
                    <div
                      className="col-md-6 col-lg-4 col-xl-3 mt-20"
                      style={{ padding: "0 30px" }}
                    >
                      <Select
                        title="Game Type"
                        name="gametype"
                        ids="gametype"
                        value={gamesFilter.gameType}
                        setValue={handleGameType}
                        options={gamesType}
                        info="showing Game type"
                      />
                    </div>
                    <div
                      className="col-md-6 col-lg-4 col-xl-3 mt-20"
                      style={{ padding: "0 30px" }}
                    >
                      <div className="CMS-formGroup">
                        <div className="CMS-formLabel">
                          {"Limits"} &nbsp;&nbsp;
                          <Tooltips info="Showing Limits" />
                        </div>
                        <MultipleSelectDDL
                          options={LimitData}
                          onSelect={handleLimit}
                          shouldReset={gamesResetLimit}
                          initialSelected={gamesLimits}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {gamesLimits.includes("Stake_Tax") && (
                      <div
                        className="col-md-6 col-lg-4 col-xl-3 mt-20"
                        style={{ padding: "0 30px" }}
                      >
                        <Input
                          title="Stake Tax %"
                          name="stakeTax"
                          value={gamesFormData.stakeTax}
                          setValue={(e) => handleChange(e, "stakeTax")}
                          placeholder="Stake Tax Percentage"
                          regex={regexType.numOnly2Digit}
                        />
                      </div>
                    )}

                    {gamesLimits.includes("Winning_Tax") && (
                      <div
                        className="col-md-6 col-lg-4 col-xl-3 mt-20"
                        style={{ padding: "0 30px" }}
                      >
                        <Input
                          title="Winning Tax %"
                          name="winningsTax"
                          value={gamesFormData.winningsTax}
                          setValue={(e) => handleChange(e, "winningsTax")}
                          placeholder="Winning Tax Percentage"
                          regex={regexType.numOnly2Digit}
                        />
                      </div>
                    )}
                  </div>
                  <div className="CMS-btnContainer">
                    <button
                      className={`CMS-btn CMS-btnSecondary active CMS-btnMedium
                          ${hasChanged ? "disable-button" : ""}
                          `}
                      type="button"
                      onClick={onSave}
                      disabled={hasChanged}
                    >
                      {COMMON_CONSTANTS.save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GamesTaxation;

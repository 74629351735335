import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi, TaxationApis } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const TaxationSlice = createSlice({
  name: "taxation",
  initialState: {
    sportsData: [],
    gamesData: [],
    filters: { gameType: "", provider: "" },
    gamesFilter: { gameType: "" },
    limits: [],
    gamesLimits: [],
    isSportsDisabled: false,
    resetLimits: false,
    gamesResetLimit: false,
    formData: {
      stakeTax: 0,
      winningsTax: 0,
      tds: 1,
      platformFee: 0,
      nftPlatformFee: 0,
      tax: 0,
      pfGstWaiver: false,
      id: 0,
      formDataByGameType: {},
    },
    gamesFormData: {
      stakeTax: 0,
      winningsTax: 0,
    },
    hasChanged: false,
  },
  reducers: {
    setSportsData: (state, action) => {
      state.sportsData = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setLimits: (state, action) => {
      state.limits = action.payload;
    },
    setIsSportsDisabled: (state, action) => {
      state.isSportsDisabled = action.payload;
    },
    setResetLimits: (state, action) => {
      state.resetLimits = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setHasChanged: (state, action) => {
      state.hasChanged = action.payload;
    },
    setFormDataByGameType: (state, action) => {
      const { gameType, formData } = action.payload;
      state.formDataByGameType[gameType] = formData;
    },
    setGamesLimits: (state, action) => {
      state.gamesLimits = action.payload;
    },
    setGamesResetLimit: (state, action) => {
      state.gamesResetLimit = action.payload;
    },
    setGamesFilter: (state, action) => {
      state.gamesFilter = { ...state.gamesFilter, ...action.payload };
    },
    setGamesData: (state, action) => {
      state.gamesData = action.payload;
    },
    setGamesFormData: (state, action) => {
      state.gamesFormData = { ...state.gamesFormData, ...action.payload };
    },
  },
});

export const {
  setSportsData,
  setFilters,
  setLimits,
  setIsSportsDisabled,
  setResetLimits,
  setFormData,
  setHasChanged,
  setFormDataByGameType,
  setGamesLimits,
  setGamesResetLimit,
  setGamesFilter,
  setGamesData,
  setGamesFormData,
} = TaxationSlice.actions;

export const fetchSportsData = (gameType, provider) => async (dispatch) => {
  try {
    const url = TaxationApis.getSportsDetails(gameType, provider || "");
    const response = await apiCaller(url, "GET");
    if (response?.status === 200) {
      dispatch(setSportsData(response.data));
      dispatch(setGamesData(response.data));
    } else {
      console.error("Failed to fetch sports data:", response?.status);
    }
  } catch (err) {
    console.error("Error in fetchSportsData:", err);
  }
};

export const saveSportstax = (data) => async (dispatch, getState) => {
  try {
    const url = TaxationApis.putSportsDetails;
    const response = await apiCaller(url, "PUT", data);
    if (response?.status === 200) {
      toaster.success(messageEnhancer(response.msg));
      dispatch(setHasChanged(true));
      const { gamesFilter } = getState().taxation;
      await dispatch(fetchSportsData(gamesFilter.gameType, gamesFilter.provider));
    } else {
      dispatch(setHasChanged(false));
      toaster.error(messageEnhancer(response.msg));
      console.error("Failed to save sports tax:", response?.status);
    }
  } catch (err) {
    console.error("Error in saveSportstax:", err);
  }
};

export default TaxationSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  callKycDocuments,
  deleteKycReport,
  setIsVisible,
  setPageOne,
  setPageSecond,
  setRecordShow,
} from "./KycDocSlice";
import { useDispatch, useSelector } from "react-redux";
import Vpagination from "../../sharedfiles/Vpagination";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import Skelton from "../Common/skelton/Skelton";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { getDateTimeFormate } from "../../sharedfiles/helper";

const KycDocuments = () => {
  const dispatch = useDispatch();
  const { kycDocuments, isVisible, pageOne, pageSecond, recordShow } =
    useSelector((state) => state.KycDocumentsSlice);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const userId = localStorage.getItem("userId");
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser);
  const tHeader = [
    [
      "ID",
      "Document Type",
      "Document Name",
      "Document ID",
      "Name",
      "DOB",
      "Status",
      "Uploaded Date & Time",
      "Actions",
    ],
  ];

  const tooltipInfo = [
    "Unique identifier for the KYC document entry",
    "Type of the KYC document (e.g., ID Proof, Address Proof)",
    "Name of the document (e.g., National ID, Passport)",
    "Unique number of the document",
    "Name of the individual associated with the document",
    "Date of birth of the individual",
    "Current status of the document (e.g., Approved)",
    "Date and time when the document was uploaded",
    "Actions available for the document (e.g., View, Delete)",
  ];
  const kycDocumentsExportPDF = () => {
    const title = "KYC Documents Report";

    const tBody =
      kycDocuments.data && kycDocuments.data.length > 0
        ? kycDocuments.data.map((doc) => [
            doc.id || "-",
            doc.docType || "-",
            doc.docName || "-",
            doc.docId || "-",
            doc.firstName && doc.lastName
              ? `${doc.firstName} ${doc.lastName}`
              : "-",
            doc.dob ? getDateTimeFormate(doc.dob) : "-",
            doc.docStatus || "-",
            getDateTimeFormate(doc.updatedDate) || "-",
            "-",
          ])
        : [];

    exportPDFC(title, tHeader, tBody);
  };
  const payload = {
    status: "KYC_PASSED",
  };
  useEffect(() => {
    dispatch(callKycDocuments(userId, pageOne - 1, 25, payload));
  }, [dispatch]);

  const callPageApi = (pageOne, pageSecond) => {
    dispatch(setIsVisible(false));
    dispatch(callKycDocuments(userId, pageOne - 1, pageSecond, payload));
  };
  const handleDelete = (id) => {
    dispatch(deleteKycReport(id));
  };

  const handleView = (doc) => {
    setSelectedImage(doc.docPath);
    setOpenDialog(true);
  };

  const onClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Dialog__Main
        title={"KYC IMAGE"}
        dOpen={openDialog}
        dClose={() => onClose()}
      >
        <div
          style={{
            msOverflow: "scroll",
            userSelect: "text",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={selectedImage} alt="KYC Document" />
        </div>
      </Dialog__Main>
      <div className="CMS-tabs-panel">
        {!isVisible ? (
          <Skelton vLen={5} hLen={7} />
        ) : (
          <div className="CMS-tabs-content">
            <div className="CMS-tabContent">
              <div className="CMS-filter-result" id="result">
                <div className="CMS-pagination">
                  <Vpagination
                    data={kycDocuments.data}
                    totalRecords={kycDocuments.totalRecords}
                    paginationFirstValue={pageOne}
                    paginationSecondValue={pageSecond}
                    setPaginationFirstValue={setPageOne}
                    setPaginationSecondValue={setPageSecond}
                    recordsShow={recordShow}
                    setRecordsShow={setRecordShow}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    csvData={kycDocuments.data}
                    exportPDF={kycDocumentsExportPDF}
                    callApi={callPageApi}
                    csvFileName={"KYC Documents Report"}
                    reloadRequired={false}
                  />
                </div>
                <div className="CMS-box ">
                  <div className="CMS-table CMS-table-triped">
                    <table>
                      <thead>
                        <tr>
                          {tHeader[0].map((header, index) => (
                            <th key={index}>
                              <div className="d-flex justify-content-center align-items-center">
                                <p className="mx-3">{header}</p>
                                <Tooltips info={`${tooltipInfo[index]}`} />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {kycDocuments &&
                        kycDocuments.data &&
                        kycDocuments.data.length > 0 ? (
                          kycDocuments.data.map((document, index) => (
                            <tr key={index}>
                              <td>{document.id ? document.id : "-"}</td>
                              <td>
                                {document.docType ? document.docType : "-"}
                              </td>
                              <td>
                                {document.docName ? document.docName : "-"}
                              </td>
                              <td>{document.docId ? document.docId : "-"}</td>
                              <td>
                                {document.firstName && document.lastName
                                  ? `${document.firstName} ${document.lastName}`
                                  : "-"}
                              </td>
                              <td>
                                {document.dob
                                  ? getDateTimeFormate(document.dob)
                                  : "-"}
                              </td>
                              <td>
                                {document.docStatus ? document.docStatus : "-"}
                              </td>
                              <td>
                                {getDateTimeFormate(document.updatedDate) ||
                                  "-"}
                              </td>
                              <td>
                                <div className="CMS-btnContainer">
                                  <div>
                                    <button
                                      type="button"
                                      className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnSmall ${
                                        viewOnlyuser ? "disable-button" : ""
                                      }`}
                                      onClick={() => handleView(document)}
                                    >
                                      View
                                    </button>
                                    <button
                                      type="button"
                                      className={`CMS-btn mt-10  CMS-btnStatus CMS-statusActive pointer CMS-btnSmall CMS-statusClosed ${
                                        viewOnlyuser ? "disable-button" : ""
                                      }`}
                                      onClick={() => handleDelete(document.id)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <No__Data__Found colspan={9} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default KycDocuments;

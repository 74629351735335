import React, { useEffect, useCallback, useMemo, useState } from "react";
import Select from "../Common/common_inputs/Select_DDL";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";
import Tooltips from "../Common/Tooltip";
import Input from "../Common/common_inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSportsData,
    saveSportstax,
    setFilters,
    setLimits,
    setIsSportsDisabled,
    setResetLimits,
    setFormData,
    setHasChanged,
} from "./TaxationSlice";
import {
    taxGameType,
    taxSportsType,
    LimitData,
    limitsData,
} from "../Common/data/mapData";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { cleanPayload, regexType } from "../../sharedfiles/helper";

const SportsTaxes = () => {
    const [gameTypeLimits, setGameTypeLimits] = useState({
        NFT: [],
        SPORTS: [],
        VIRTUALS: [],
    });
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const dispatch = useDispatch();
    const {
        sportsData,
        filters,
        limits,
        isSportsDisabled,
        resetLimits,
        formData,
        hasChanged,
    } = useSelector((state) => state.taxation);

    useEffect(() => {
        if (sportsData?.length > 0) {
            const data = sportsData[0];

            dispatch(
                setFormData({
                    stakeTax: data?.stakeTaxPercentage || 0,
                    winningsTax: data?.winningsTaxPercentage || 0,
                    tds: data?.tds || 0,
                    platformFee: data?.platformFeePercentage || 0,
                    nftPlatformFee: data?.nftPlatformFee || 0,
                    pfGstWaiver: data?.pfGstWaiver || false,
                    id: data?.id,
                    tax: data?.platformFeePercentage || 0,
                })
            );
        }
    }, [sportsData, dispatch]);

    useEffect(() => {
        if (filters.gameType) {
            const allLimits = filters.gameType === "NFT" ? limitsData : LimitData;
            const allLimitValues = allLimits.map((option) => option.value);

            if (isInitialLoad || !gameTypeLimits[filters.gameType].length) {
                dispatch(setLimits(allLimitValues));
                setGameTypeLimits((prevLimits) => ({
                    ...prevLimits,
                    [filters.gameType]: limits,
                }));
            } else {
                dispatch(setLimits(gameTypeLimits[filters.gameType]));
            }

            dispatch(setResetLimits(true));
            setIsInitialLoad(false);
        }
    }, [filters.gameType, gameTypeLimits, dispatch, isInitialLoad]);
    
    const handleInputChange = useCallback(
        (event, field) => {
            const value = event.target.value;
            dispatch(setFilters({ [field]: value }));
            if (
                (field === "gameType" && filters.provider) ||
                (field === "provider" && filters.gameType)
            ) {
                dispatch(
                    fetchSportsData(
                        field === "gameType" ? value : filters.gameType,
                        field === "provider" ? value : filters.provider
                    )
                );
            }
        },
        [filters, dispatch]
    );




    const handleGameType = useCallback(
        (event) => {
            const selectedGameType = event.target.value;
            dispatch(setResetLimits(true));
            dispatch(setFilters({ gameType: selectedGameType, provider: "" }));
            dispatch(setLimits([]));
            dispatch(
                setIsSportsDisabled(
                    selectedGameType === "NFT" ||
                    selectedGameType === "SPORTS" ||
                    selectedGameType === "VIRTUALS"
                )
            );
            if (selectedGameType === "VIRTUALS") {
                dispatch(
                    setFilters({ gameType: selectedGameType, provider: "TRIDENT" })
                );
            }
            dispatch(setLimits(gameTypeLimits[selectedGameType] || []));
            if (selectedGameType === "NFT" || selectedGameType === "SPORTS") {
                dispatch(fetchSportsData(selectedGameType, ""));
            } else {
                dispatch(fetchSportsData(selectedGameType, "TRIDENT"));
            }
        },
        [dispatch]
    );

    const handleLimit = useCallback(
        (value) => {
            setGameTypeLimits((prevLimits) => ({
                ...prevLimits,
                [filters.gameType]: value,
            }));
            dispatch(setLimits(value));
        },
        [filters.gameType, dispatch]
    );

    const handleChange = useCallback(
        (event, field) => {
            const value = event.target.value;
            dispatch(setFormData({ [field]: +value }));
            // dispatch(setHasChanged(true));
        },
        [dispatch]
    );

    const onSave = useCallback(() => {
        let payload = {
            gameType: filters.gameType,
            provider: filters.provider,
            id: formData.id,
        };

        if (limits.includes("Stake_Tax"))
            payload.stakeTaxPercentage = formData.stakeTax;
        if (limits.includes("Winning_Tax"))
            payload.winningsTaxPercentage = formData.winningsTax;

        if (filters.gameType === "NFT") {
            if (limits.includes("platform_fee_tax")) {
                payload.platformFeePercentage = formData.platformFee;
                payload.nftPlatformFee = formData.nftPlatformFee;
                payload.pfGstWaiver = formData.pfGstWaiver;
            }
            if (limits.includes("TDS")) payload.tds = formData.tds;
        }
        const cleanedPayload = cleanPayload(payload);
        dispatch(saveSportstax(cleanedPayload));
        dispatch(setHasChanged(true));
        setTimeout(() => {
            dispatch(setHasChanged(false));
        }, 3000);
    }, [filters, formData, limits, dispatch]);

    const limitOptions = useMemo(
        () => (filters.gameType === "NFT" ? limitsData : LimitData),
        [filters.gameType]
    );

    function handleCheckBox(e) {
        const { checked } = e.target;
        dispatch(setFormData({ pfGstWaiver: checked }));
        // dispatch(setHasChanged(true));
    }
    return (
        <React.Fragment>
            <div className="CMS-tab-panel active" id="CMS-playerActivity">
                <div className="CMS-tabContent">
                    <div className="CMS-box CMS-box-content">
                        <div className="row mb-10">
                            <div className="col-12">
                                <div className="CMS-cardBox" style={{ paddingBottom: "20px" }}>
                                    <div className="row">
                                        <div
                                            className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                            style={{ padding: "0 30px" }}
                                        >
                                            <Select
                                                title="Game Type"
                                                name="gametype"
                                                ids="gametype"
                                                value={filters.gameType}
                                                setValue={handleGameType}
                                                options={taxGameType}
                                                info="showing game type"
                                            />
                                        </div>

                                        <div
                                            className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                            style={{ padding: "0 30px" }}
                                        >
                                            <Select
                                                title="Sports"
                                                name="sports"
                                                ids="sports"
                                                // value={
                                                //   isSportsDisabled ? "All Sports" : filters.provider
                                                // }
                                                // setValue={(val) => handleInputChange(val, "provider")}
                                                // options={sportsOptions}
                                                info="showing sports"
                                                disabled={isSportsDisabled}
                                                sportsFreez={isSportsDisabled}
                                            />
                                        </div>
                                        {filters.gameType === "VIRTUALS" && (
                                            <div
                                                div
                                                className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                style={{ padding: "0 30px" }}
                                            >
                                                <Select
                                                    title="Providers"
                                                    name="provider"
                                                    ids="provider"
                                                    value={filters.provider}
                                                    setValue={(val) => handleInputChange(val, "provider")}
                                                    options={taxSportsType}
                                                    info="showing Providers"
                                                />
                                            </div>
                                        )}

                                        <div
                                            className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                            style={{ padding: "0 30px" }}
                                        >
                                            <div className="CMS-formGroup">
                                                <div className="CMS-formLabel">
                                                    {"Limits"} &nbsp;&nbsp;
                                                    <Tooltips info="Showing Limits" />
                                                </div>
                                                <MultipleSelectDDL
                                                    options={limitOptions}
                                                    onSelect={handleLimit}
                                                    shouldReset={resetLimits}
                                                    initialSelected={limits}
                                                />
                                            </div>
                                        </div>

                                        {filters.gameType === "NFT" &&
                                            limits.includes("platform_fee_tax") && (
                                                <div className="CMS-formGroup formGroup-inline mb-0">
                                                    <div className="CMS-formControl">
                                                        <div className="CMS-toggleSwitch">
                                                            <input
                                                                id="toggleActive"
                                                                name="toggleActive"
                                                                type="checkbox"
                                                                checked={formData.pfGstWaiver}
                                                                onChange={(e) => handleCheckBox(e)}
                                                                style={{ zoom: 1.5 }}
                                                            />
                                                            <label htmlFor="toggleActive"></label>
                                                        </div>
                                                    </div>
                                                    <div className="CMS-formLabel pl-10">
                                                        PF & Tax Waive Off
                                                    </div>
                                                </div>
                                            )}
                                    </div>

                                    <div className="row">
                                        {limits.includes("TDS") && (
                                            <div
                                                className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                style={{ padding: "0 30px" }}
                                            >
                                                <Input
                                                    title="TDS%"
                                                    name="tds"
                                                    value={formData.tds}
                                                    setValue={(e) => handleChange(e, "tds")}
                                                    placeholder="TDS Percentage"
                                                    regex={regexType.numOnly2Digit}
                                                />
                                            </div>
                                        )}

                                        {
                                            limits.includes("platform_fee_tax") && (
                                                <>
                                                    <div
                                                        className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                        style={{ padding: "0 30px" }}
                                                    >
                                                        <Input
                                                            title="Platform Fee"
                                                            name="platformFee"
                                                            value={formData.platformFee}
                                                            setValue={(e) => handleChange(e, "platformFee")}
                                                            placeholder="Platform Fee"
                                                            regex={regexType.numOnly2Digit}
                                                        />
                                                    </div>
                                                    <div
                                                        className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                        style={{ padding: "0 30px" }}
                                                    >
                                                        <Input
                                                            title="Tax %"
                                                            name="tax"
                                                            value={formData.tax}
                                                            setValue={(e) => handleChange(e, "tax")}
                                                            placeholder="Tax %"
                                                            regex={regexType.numOnly2Digit}
                                                        />
                                                    </div>
                                                    <div
                                                        className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                        style={{ padding: "0 30px" }}
                                                    >
                                                        <Input
                                                            title="NFT Trade Fee"
                                                            name="nftPlatformFee"
                                                            value={formData.nftPlatformFee}
                                                            setValue={(e) =>
                                                                handleChange(e, "nftPlatformFee")
                                                            }
                                                            placeholder="NFT Trade Fee"
                                                            regex={regexType.numOnly2Digit}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        {limits.includes("Stake_Tax") &&
                                            (filters.gameType === "SPORTS" ||
                                                filters.gameType === "VIRTUALS") && (
                                                <div
                                                    className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                    style={{ padding: "0 30px" }}
                                                >
                                                    <Input
                                                        title="Stake Tax %"
                                                        name="stakeTax"
                                                        value={formData.stakeTax}
                                                        setValue={(e) => handleChange(e, "stakeTax")}
                                                        placeholder="Stake Tax Percentage"
                                                        regex={regexType.numOnly2Digit}
                                                    />
                                                </div>
                                            )}

                                        {limits.includes("Winning_Tax") &&
                                            (filters.gameType === "SPORTS" ||
                                                filters.gameType === "VIRTUALS") && (
                                                <div
                                                    className="col-md-6 col-lg-4 col-xl-3 mt-20"
                                                    style={{ padding: "0 30px" }}
                                                >
                                                    <Input
                                                        title="Winning Tax %"
                                                        name="winningsTax"
                                                        value={formData.winningsTax}
                                                        setValue={(e) => handleChange(e, "winningsTax")}
                                                        placeholder="Winning Tax Percentage"
                                                        regex={regexType.numOnly2Digit}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                    <div className="CMS-btnContainer">
                                        <button
                                            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium
                          ${hasChanged ? "disable-button" : ""}
                          `}
                                            type="button"
                                            onClick={onSave}
                                            disabled={hasChanged}
                                        >
                                            {COMMON_CONSTANTS.save}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SportsTaxes;

import { Currency } from "../../../../sharedfiles/helper";

export const sportGameType = [
  { id: 2, label: "Sports", value: "SPORTS" },
  { id: 1, label: "Virtuals", value: "VIRTUALS" },
];
export const gamesGameType = [
  { id: 3, label: "Casino", value: "CASINO" },
  { id: 4, label: "NE Games", value: "NE_GAMES" },
  { id: 5, label: "Lottery", value: "LOTTERY" },
]
export const BetType = [
  // { id: 1, label: "Combi", value: "COMBI" },
  // { id: 2, label: "Custom", value: "CUSTOM" },
  { id: 4, label: "Single", value: "SINGLE" },
  { id: 3, label: "Multi", value: "MULTI" },
  // { id: 5, label: "Split", value: "SPLIT" },
  // { id: 6, label: "System", value: "SYSTEM " },
];

export const WatchlistTypes = [
  { id: 1, label: "Manual", value: "MANUAL" },
  { id: 2, label: "Automated", value: "AUTOMATED" },
]
export const sportsInBetHistory = [
  { id: 1, label: "Soccer", value: "Soccer" },
]
export const SportsBetType = [
  { id: 1, label: "Combi", value: "COMBI" },
  { id: 2, label: "Custom", value: "CUSTOM" },
  { id: 4, label: "Single", value: "SINGLE" },
  { id: 3, label: "Multi", value: "MULTI" },
  { id: 5, label: "Split", value: "SPLIT" },
  { id: 6, label: "System", value: "SYSTEM " },
];

export const CasinoType = [
  { id: 1, label: "Category", value: "CATEGORY", tooltip: "Showing Category" },
  // { id: 2, label: "Game Code", value: "GAME_CODE" },
  { id: 3, label: "Game Name", value: "GAME_NAME", tooltip: "Showing Game Name" },
  { id: 4, label: "Aggregator", value: "PROVIDER_NAME", tooltip: "Showing Aggregator" },
  { id: 5, label: "Provider", value: "SUB_PROVIDER_NAME", tooltip: "Showing Provider" },
];

export const BetStatus = [
  { id: 1, label: "Half Won", value: "HalfWon" },
  { id: 2, label: "half Lost", value: "HalfLost" },
  { id: 3, label: "Rejected", value: "Rejected" },
  { id: 4, label: "Cancelled", value: "Cancelled" },
  { id: 5, label: "Void", value: "Void" },
  { id: 6, label: "Cashed Out", value: "CashedOut" },
  { id: 7, label: "Running", value: "Running" },
  { id: 8, label: "Won", value: "Won" },
  { id: 9, label: "Lost", value: "Lost" },
  { id: 10, label: "InitFailed", value: "InitFailed" },
  { id: 11, label: "Init", value: "Init" },
  // { id: 12, label: "Live", value: "Live" },
  { id: 13, label: "Settled", value: "Settled" },
];

export const Betfold = [
  { id: 2549, label: "1", value: "SINGLE" },
  { id: 9243, label: "2", value: "DOUBLE" },
  { id: 9324, label: "3", value: "TREBLE" },
  { id: 9757, label: "Multiple 4+", value: "MULTIPLE" },
];
export const userRole = [
  { id: 1, label: "Agent", value: "AGENT" },
  { id: 2, label: "Online User", value: "ONLINE_PLAYER" },
  { id: 3, label: "Cashier", value: "CASHIER" },
  { id: 4, label: "player", value: "PLAYER" },
]

export const Sport = [
  { id: 957, label: "Aussie Rules", value: "AussieRules" },
  { id: 97867, label: "Badminton", value: "Badminton" },
  { id: 987967, label: "Baseball", value: "Baseball" },
  { id: 9796, label: "Basket ball", value: "BasketBall" },
  { id: 97697, label: "Basketball 3x3", value: "Basketball3x3" },
  { id: 379, label: "Beach VolleyBall", value: "BeachVolleyBall" },
  { id: 7369, label: "Casinos", value: "Casinos" },
  { id: 769, label: "Cricket", value: "Cricket" },
  { id: 769, label: "Darts", value: "Darts" },
  { id: 769, label: "Field Hockey", value: "FieldHockey" },
  { id: 976, label: "Football", value: "Football" },
  { id: 7699, label: "Formula 1", value: "Formula1" },
  { id: 79, label: "Futsal", value: "Futsal" },
  { id: 47369, label: "Golf", value: "Golf" },
  { id: 439, label: "Handball", value: "Handball" },
  { id: 439, label: "Horse Racing", value: "HorseRacing" },
  { id: 639, label: "Ice Hockey", value: "Ice Hockey" },
  { id: 7969, label: "Tennis", value: "Tennis" },
  { id: 7679, label: "Kabaddi", value: "Kabaddi" },
  { id: 979, label: "Snooker", value: "Snooker" },
  { id: 68739, label: "Soccer", value: "Soccer" },
  { id: 769, label: "Squash", value: "Squash" },
  { id: 7369, label: "American Football", value: "AmericanFootball" },
  { id: 75739, label: "Rugby", value: "Rugby" },
  { id: 7379, label: "ESports", value: "ESports" },
  { id: 379, label: "Volley Ball", value: "VolleyBall" },
  { id: 7699, label: "Table Tennis", value: "Table Tennis" },
];

export const mainTableHeaders = [
  "Bet Id",
  "Booking Code",
  "Short Bet ID",
  "Bet Status",
  "Rejection Code",
  "Age Of Account",
  "Customer Id",
  "User Role",
  "User Name",
  "First Name",
  "Last Name",
  "Surname",
  "Phone",
  "Stake Factor",
  "Event Name",
  "Betfold",
  "Bet Type",
  "Currency",
  "Odds",
  `Stake`,
  "Place Bet Type",
  `Match status`,
  // `Real Stake`,
  // `Bonus Stake`,
  "Game Type",
  "Bonus Amount",
  "Bonus Type",
  `Potential Payout`,
  `Payout`,
  "Placed On",
  "Settled On",
  `Stake Tax ${Currency()}`,
  `Stake After Tax ${Currency()}`,
  `Stake Tax Amount  ${Currency()}`,
  `Winnings Tax ${Currency()}`,
  `Winnings Tax Amount ${Currency()}`,
  "Total Payout",
  "Total Payout With Bonus",
  "Bonus Max Win Amount",
  "Bonus Max Win Percentage",
  "Sub Bet Type",
  "Agent ID",
  "Agent Name",
  "Provider Name",
  // "Number Of Combination",
  // "Win/Lost/Void",
  // "Groups",
  "Aggregator",
  "Provider",
  "Game Name",
  "Category",
  // "Game Code",
];

export const gamesTableHeaders = [
  "Bet Id",
  "Bet Status",
  "Age Of Account",
  "Customer Id",
  "User Role",
  "User Name",
  "First Name",
  "Last Name",
  "Surname",
  "Phone",
  "Game Type",
  "Aggregator",
  "Provider",
  "Game Name",
  "Category",
  "Event Name",
  "Placed On",
  "Settled On",
  "Currency",
  "Bonus Amount",
  "Payout",
  "Stake",
  `Stake Tax ${Currency()}`,
  `Stake After Tax ${Currency()}`,
  `Stake Tax Amount  ${Currency()}`,
  `Winnings Tax ${Currency()}`,
  `Winnings Tax Amount ${Currency()}`,
  "Total Payout",
  "Total Payout With Bonus",
  "Bonus Max Win Amount",
  "Bonus Max Win Percentage",
  "Place Bet Type",
  "Agent ID",
  "Agent Name",
]

export const bettingHistoryInfo = [
  "Showing Bet Id",
  "Showing Booking Code",
  "Showing Short Bet Id",
  "Showing Bet Status",
  "Showing Rejection Code",
  "Showing Age Of Account",
  "Showing Customer Id",
  "Showing User Role",
  "Showing User Name",
  "Showing First Name",
  "Showing Last Name",
  "Showing Surname",
  "Showing Phone",
  "Showing Stake Factor",
  "Showing Event Name",
  "Showing Betfold",
  "Showing Bet Type",
  "Showing Currency",
  "Showing Odds",
  `Showing Stake`,
  "Showing Place Bet Type",
  `Showing Match status`,
  // `Showing Real Stake`,
  // `Showing Bonus Stake`,
  "Showing Game Type",
  "Showing Bonus Amount",
  "Showing Bonus Type",
  `Showing Potential Payout`,
  `Showing Payout`,
  "Showing bets Placed On",
  "Showing the bets Settled On",
  "Showing Stake Tax",
  "Showing Stake After Tax",
  "Showing Stake Tax Amount",
  "Showing Winnings Tax",
  "Showing Winnings Tax Amount",
  "Showing Total Payout",
  "Showing Total Payout With Bonus",
  "Showing Bonus Max Win Amount",
  "Showing Bonus Max Win Percentage",
  "Showing Sub Bet Type",
  "Showing Agent Id",
  "Showing Agent Name",
  "Showing Provider Name",
  // "Showing Number Of Combination",
  // "Showing Win Lost Void Outcomes Count",
  // "Showing Groups",
  "Showing Aggregator",
  "Showing Provider",
  "Showing Game Name",
  "Showing Category",
  // "Showing Game Code",
]
export const gamesHistoryInfo = [
  "Showing Bet Id",
  "Showing Bet Status",
  "Showing Age Of Account",
  "Showing Customer Id",
  "Showing User Role",
  "Showing User Name",
  "Showing First Name",
  "Showing Last Name",
  "Showing Surname",
  "Showing Phone",
  "Showing Game Type",
  "Showing Aggregator",
  "Showing Provider",
  "Showing Game Name",
  "Showing Category",
  "Showing Event Name",
  "Showing bets Placed On",
  "Showing the bets Settled On",
  "Showing Currency",
  "Showing Bonus Amount",
  `Showing Payout`,
  `Showing Stake`,
  "Showing Stake Tax",
  "Showing Stake After Tax",
  "Showing Stake Tax Amount",
  "Showing Winnings Tax",
  "Showing Winnings Tax Amount",
  "Showing Total Payout",
  "Showing Total Payout With Bonus",
  "Showing Bonus Max Win Amount",
  "Showing Bonus Max Win Percentage",
  "Showing Place Bet Type",
  "Showing Agent ID",
  "Showing Agent Name",
]

export const betIdHeaders = [
  "Bet Selection ID",
  "Event Name",
  "Event start date & Time",
  "Event Status",
  "League Name",
  "Market Name",
  "Odds",
  "Sports Name",
  "Selection Name",
  "Selection Status",
  "Winning Number"
];


export const betIdHeaderInfo = [
  "Showing Bet Selection ID",
  "Showing Event Name",
  "Showing Event start date & Time",
  "Showing Event Status",
  "Showing League Name",
  "Showing Market Name",
  "Showing Odds",
  "Showing Sports Name",
  "Showing Selection Name",
  "Showing Selection Status",
  "Showing Winning Number",
];

export const betSortables = {
  "Bet Status": {
    key: "betStatus",
    type: "str"
  },
  "Rejection Code": {
    key: "rejectionCode",
    type: "str"
  },
  "Age Of Account": {
    key: "ageOfAccount",
    type: "num"
  },
  "Customer Id": {
    key: "userId",
    type: "num"
  },
  "Stake Factor": {
    key: "stakeFactor",
    type: "num"
  },
  "Betfold": {
    key: "betType",
    type: "num"
  },
  "Currency": {
    key: "currencyCode",
    type: "str"
  },
  "Odds": {
    key: "odds",
    type: "num"
  },
  "Stake": {
    key: "stake",
    type: "num"
  },
  "Real Stake": {
    key: "stake_real",
    type: "num"
  },
  "Bonus Stake": {
    key: "stake_bonus",
    type: "num"
  },
  "Bonus Amount": {
    key: "parlayWinning",
    type: "num"
  },
  "Bonus Type": {
    key: "bonusPayout",
    type: "num"
  },
  "Potential Payout": {
    key: "potentialPayout",
    type: "num"
  },
  "Payout": {
    key: "payout",
    type: "num"
  },
  "Placed On": {
    key: "placedDate",
    type: "date"
  },
  "Settled On": {
    key: "settledDate",
    type: "date"
  },
}

export const GamesbetSortables = {
  "Bet Status": {
    key: "betStatus",
    type: "str"
  },
  "Rejection Code": {
    key: "rejectionCode",
    type: "str"
  },
  "Age Of Account": {
    key: "ageOfAccount",
    type: "num"
  },
  "Customer Id": {
    key: "userId",
    type: "num"
  },
  "Stake Factor": {
    key: "stakeFactor",
    type: "num"
  },
  "Betfold": {
    key: "betType",
    type: "num"
  },
  "Currency": {
    key: "currencyCode",
    type: "str"
  },
  "Odds": {
    key: "odds",
    type: "num"
  },
  "Stake": {
    key: "stake",
    type: "num"
  },
  "Real Stake": {
    key: "stake_real",
    type: "num"
  },
  "Bonus Stake": {
    key: "stake_bonus",
    type: "num"
  },
  "Bonus Amount": {
    key: "parlayWinning",
    type: "num"
  },
  "Bonus Type": {
    key: "bonusPayout",
    type: "num"
  },
  "Potential Payout": {
    key: "potentialPayout",
    type: "num"
  },
  "Payout": {
    key: "payout",
    type: "num"
  },
  "Placed On": {
    key: "placedDate",
    type: "date"
  },
  "Settled On": {
    key: "settledDate",
    type: "date"
  },
}
import { biVipLink } from "../../sharedfiles/EndpointConfig";

export const VipBiReports = [
    {
        id: 1,
        ReportCatagory: "VIP Reports",
        Reports: [
            {
                id: 1,
                name: "VIP Reports",
                url: biVipLink.vipReportDev
            }
        ]
    },
    {
        id: 2,
        ReportCatagory: "VIP Reports",
        Reports: [
            {
                id: 1,
                name: "VIP Reports",
                url: biVipLink.vipReportProd
            }
        ]
    },

]